
































































import { Component, Vue, Prop } from "vue-property-decorator";

import FunnelStepsPreviewFilter from "@/funnels/components/FunnelStepsPreviewFilter.vue";
import { FunnelStepModel } from "@/funnels/models/FunnelModel";

@Component({
  components: {
    FunnelStepsPreviewFilter,
  },
})
export default class FunnelStepsPreview extends Vue {
  @Prop() steps!: Array<FunnelStepModel>;
  @Prop({ default: true }) stepsVisible!: boolean;

  get dark(): boolean {
    return this.$vuetify.theme.dark;
  }
}
